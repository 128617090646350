@import '../../../../../styles/variables';

.orderConfirmedBanner {
  background-color: $type-light-primary;
  padding: 1em;
  color: $type-dark-primary;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 0.5em;
  svg {
    min-width: 30px;
    min-height: 30px;
    max-width: 40px;
    max-height: 40px;
  }

  .text {
    margin: 5px 0;
  }

  .confirmedText {
    font-size: 20px;
    font-weight: 700;
    margin: 0;

    svg {
      margin-right: 0.5em;
      min-width: 10px;
      min-height: 10px;
      > path {
        stroke: $yellow;
      }
    }
    span {
      vertical-align: super;
    }
  }

  .emailText {
    font-size: 14px;
    font-weight: 600;
    opacity: 0.75;
    margin: 0.3em 0;
  }
}

.orderConfirmedBanner.original {
  margin: 1em 0;
  width: 90%;
  border-radius: 10px;

  .confirmedText {
    margin: 0.4em 0;
  }
}

@media (max-width: $sm-min-width) {
  .orderConfirmedBanner {
    flex-direction: row;
    text-align: left;
    .text {
      margin: 0 0.5em;
    }
    .confirmedText {
      font-size: 14px;
    }
    .emailText {
      font-size: 12px;
      line-height: 150%;
      margin: 0.3em 0 0;
    }
  }

  .orderConfirmedBanner.original {
    width: 85%;
    margin-top: 0;

    .confirmedText {
      margin-top: 0;
    }
  }
}
