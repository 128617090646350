@import '../../../styles/mixins';
@import '../../../styles/variables';

.main {
  @include webview-full-height();
  width: 100%;

  .subheader {
    font-size: 12px;
    margin: 27px 27px 0 24px;
    color: #737373;
  }

  h1 {
    margin: 0 27px 32px 24px;
    text-transform: unset;
    font-family: $font-default;
    font-weight: 600;
    font-size: 24px;
    line-height: 27px;
    letter-spacing: -0.02em;
  }

  .paymentInfoContainer {
    margin-top: 32px;
    border-top: 8px solid #f5f5f2;
    border-bottom: 1px solid #f5f5f2;
    padding: 33px 27px 33px 24px;
  }

  .giftCardContainer {
    display: flex;
    justify-content: space-between;
    border-bottom: 8px solid #f5f5f2;
    padding: 33px 27px 35px 24px;

    &:has(input) {
      flex-direction: column;
      align-items: stretch;
    }

    .giftCardHeader {
      font-family: $font-default;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: -0.01em;
      color: #000000;
    }

    .plusButton {
      width: 14px;
      height: 14px;
    }
  }

  .summaryContainer {
    padding: 49px 27px 44px 24px;
  }

  .actionContainer {
    padding: 0 24px 48px;
    display: flex;
    flex-direction: column;
    gap: 1em;

    button {
      width: 100%;
    }
  }

  .errorContainer {
    padding: 0 20px 10px 20px;
    font-size: 18px;
    text-align: center;
    color: $red;
  }
}
