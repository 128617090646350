@import '../../../../../styles/variables';

.container {
  composes: container from '../../../../../styles/container.module.scss';
  max-width: 834px;
  margin: 0 auto; // Center the container since it's outside the product container
}

.heading {
  font-family: $font-title;
  text-align: center;
  font-size: 2rem;
  line-height: 1.1em;

  @media (min-width: $md-min-width) {
    font-size: 3.5em;
  }
}

table,
th,
td {
  padding: 0.5em;
  border: 1px solid;
  border-collapse: collapse;
}

.decagonChatContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  padding: 24px;
  margin-top: 32px;
  gap: 16px;
  border-radius: 24px;
  background: #f3f3f2;

  @media (max-width: 1024px) {
    gap: 8px;
  }
}

.decagonChatContainer svg {
  height: 44px;
  width: 44px;

  @media (max-width: 1024px) {
    height: 32px;
    width: 32px;
  }
}

.decagonChatTitle {
  align-self: stretch;
  text-align: center;
  font-family: $font-default;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;

  @media (max-width: 1024px) {
    font-size: 18px;
  }
}

.decagonChatButton {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 55px;
  padding: 18px 32px;
  gap: 12px;
  border-radius: 100px;
  color: black;
  background-color: transparent;
  border: 1px solid black;
  cursor: pointer;

  text-align: center;
  font-family: $font-default;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: -0.16px;

  margin-top: 8px;
}
