@import '../../../../styles/variables';

.supplementPillContainer {
  padding: 22px;
  border-radius: 24px;
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  font-family: $font-default;

  &.informational {
    background-color: white;
    border: 2px solid $gray-300;

    .divider {
      border: 0.5px solid $gray-100;
      margin: 0.7em 0;
    }

    .deliveryExpected,
    .trackingLink {
      margin: 0;
      font-size: 12px;
      color: black;
    }

    .shipping {
      margin: 5px 0;
    }
  }

  &.selectable {
    border: 2px solid $gray-400;
  }

  &.selected {
    border: 2px solid black;
  }

  h2 {
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    letter-spacing: -0.4px;
    margin: 0;
    padding: 0.2em 0;
  }
  .row {
    display: flex;

    .col {
      flex: 30;
    }

    .status {
      color: $green-400;
      padding: 0;
      margin: 0;
      font-size: 12px;
      font-weight: 600;
    }
    button {
      font-weight: 400;
      padding: 16px;
      height: 34px;
      margin-left: 10px;
      background-color: $gray-300;
      border: none;
      padding: 20px;
      font-size: 12px;
    }
    .col {
      button {
        font-weight: 400;
        background-color: $gray-200;
        border: none;
        font-size: 12px;
        padding: 16px;
        height: 34px;
      }
    }
  }

  .shipping {
    font-weight: 600;
    font-size: 14px;
  }

  .recommended {
    font-weight: 300;
    font-size: 12px;
  }

  .nextShipDate {
    font-weight: 300;
    font-size: 11px;
    padding-top: 0.5em;
  }

  .billedAs {
    color: $type-light-secondary;
    font-weight: 600;
    font-size: 12px;
  }

  .billedAs {
    color: $type-light-secondary;
    font-weight: 300;
    font-size: 12px;
    .emphasize {
      font-weight: 600;
    }
  }

  .nextShipment {
    font-weight: 300;
  }
}

.purchasableOption {
  height: 11em;
  border: 2px solid $gray-100;
  display: flex;
  font-family: $font-default;
  border-radius: 1.5em;
  align-items: center;
  cursor: pointer;
  box-sizing: border-box;
  padding: 1.2em;

  .shipmentInfo {
    width: 150%;
  }
  &.selected {
    border: 2px solid black;
  }

  svg.hideDown {
    display: none;
  }
}
