@import '../../styles/variables';

.variant {
  composes: variant from '../../styles/variants.module.scss';
}

:global(.ecom-flow-revamp) .variant {
  border-top: 8px #f3f3f2 solid;
  padding: 32px 24px 32px 24px;
}

.variantTitleGroup {
  composes: variantTitleGroup from '../../styles/variants.module.scss';
}

.variantTitleGroupTest {
  margin-bottom: 6px !important;
}

.variantTitle {
  composes: variantTitle from '../../styles/variants.module.scss';
}

.variantSubtitle {
  composes: variantSubtitle from '../../styles/variants.module.scss';
}

.variantTitleError {
  composes: variantTitleError from '../../styles/variants.module.scss';
}

.variantInfo {
  composes: variantInfo from '../../styles/variants.module.scss';
}

.moreInfo {
  composes: moreInfo from '../../styles/variants.module.scss';
}

.noSubscriptionOption {
  font-family: $font-default;
  font-size: 1rem;
  font-weight: 600;
}

.disabledSection {
  color: #999;
  pointer-events: none;
  user-select: none;
  opacity: 0.5;
}
