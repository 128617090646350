@import '../../styles/variables';

.container {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: calc($app-bar-height-mobile + env(safe-area-inset-top));
  padding: env(safe-area-inset-top) 0 1em;
  font-size: 1rem;
  position: sticky;
  top: 0;
  z-index: 100;
  background-color: #ffffff;

  &.darkBackground {
    background-color: transparent;

    svg,
    svg path {
      fill: #ffffff;
    }
  }
}

.button {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3em;
  height: 3em;
}
