.container {
  composes: container from '../../../../styles/container.module.scss';
}

.viewBillingInfo {
  line-height: 1.5em;
}

.billingDetails {
  margin-bottom: 2em;
}

.unlinkBillingCta {
  text-decoration: underline;
  cursor: pointer;
}

.creditCardBrand {
  font-weight: 700;
}

.newBillingAddressForm {
  composes: form from '../../../../styles/form.module.scss';
  margin-top: 1em;
}

.formSection {
  composes: formSection from '../../../../styles/form.module.scss';
}

.formRow {
  composes: formRow from '../../../../styles/form.module.scss';
}
