@import 'variables';

.form {
  display: flex;
  flex-direction: column;
  width: 100%;
  font-family: $font-default;

  h4 {
    font-size: 1.5rem;
    margin-bottom: 1.5em;
  }

  h5 {
    font-size: 16px;
  }

  input,
  select,
  textarea {
    font-family: $font-default;
    font-weight: 400;
    background: none;
    width: auto;
  }

  input:focus,
  select:focus,
  textarea:focus {
    outline: none;
  }

  input[type='email'],
  input[type='password'],
  input[type='tel'],
  input[type='text'],
  :global(.recurly-element) {
    width: auto;
    background-color: #ffffff;
    font-size: 1rem;
    padding: 1.5em 2em;
    box-sizing: border-box;
    border-radius: 2em;
    border: 1px solid #bebebb;
    margin: 0 0 1em;

    &:focus {
      outline: none;
    }
  }

  :global(.recurly-element) {
    height: 4em;
    width: 100%;
    padding: 0 2em;
  }

  textarea {
    border: 1px solid #d8d8d8;
    padding: 10px;
  }
}

.formSection {
  padding-bottom: 1em;
  margin-bottom: 1em;
  display: flex;
  flex-direction: column;
}

.form.compact {
  .formSection {
    border-bottom: 8px solid #f5f5f2;
  }
}

.formRow {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;

  & > * {
    flex: 1 1 0;
    width: 100%;
  }
}

.formActions {
  margin-bottom: 1em;
}

@media (min-width: $md-min-width) {
  .formRow {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 1em;
  }
}

.formError {
  font-family: $font-default;
  color: $red;
  line-height: 1.4em;

  a {
    color: $type-light-primary;
    text-decoration: underline;
  }
}
