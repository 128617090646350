@import '../../../../../styles/variables';

.container {
  padding: 30px 0px;
  border-bottom: 1px solid $gray-100;

  &:first-child {
    padding-top: 10px;
  }
}

.titleContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  cursor: pointer;
}

.titleText {
  font-size: 1rem;
  font-weight: 600;
}

.toggle {
  font-size: 1.5rem;
  line-height: 1rem; // Set to the same line-height as the .titleText
  padding-left: 10px;
  font-family: monospace; // To keep the + / - symbols the same width
}

.body {
  padding-top: 30px;
  color: $type-light-secondary;
}
