@import '../../../../../styles/variables';

.container {
  font-family: $font-default;
  font-size: 1rem;
  font-weight: 500;
  border-radius: 1.5em;
  background-color: $bg-light-secondary;
  padding: 2em 1.5em;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-flow: column;
  column-gap: 3em;
  row-gap: 1.5em;

  @media (max-width: $sm-max-width), (min-width: $lg-min-width) and (max-width: $lg-max-width) {
    display: flex;
    flex-direction: column;
    gap: 1.5em;
  }
}

.valueProp {
  display: flex;
  flex-direction: row;
  gap: 1em;
  align-items: center;

  .icon {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $bg-light-primary;
    border-radius: 0.5em;
    box-sizing: border-box;
    padding: 10px;
  }

  .icon.fade {
    path {
      fill: $type-light-secondary;
    }
  }

  .text {
    color: $type-light-primary;
    font-family: $font-default;
    font-size: 0.875rem;
    line-height: 1.4em;
    font-weight: 500;
  }

  .text.fade > div {
    color: $type-light-secondary;
  }
}

:global(.ecom-flow-revamp) .container {
  padding: 20px;
  gap: 16px;
  display: flex;
  flex-direction: column;
}

:global(.ecom-flow-revamp) .valueProp {
  margin-left: 2px;
  gap: 12px;
}

:global(.ecom-flow-revamp) .valueProp .icon,
:global(.ecom-flow-revamp) .valueProp .icon svg {
  background-color: unset;
  padding: 0;
  height: 20px;
  width: 20px;
}
