@import '../../../../styles/variables';

.variant {
  composes: variant from '../../../../styles/variants.module.scss';
}

.variantTitleGroup {
  composes: variantTitleGroup from '../../../../styles/variants.module.scss';
  width: 100%;
}

:global(.ecom-flow-revamp) .variantTitleGroup {
  margin: 24px 0px 0px 0px;
}

.variantTitle {
  composes: variantTitle from '../../../../styles/variants.module.scss';
}

.selectedVariantMakerName {
  font-family: $font-default;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  color: #737373;
}

.selectedVariantMakerDisclaimer {
  font-family: $font-default;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  color: #737373;
  margin-bottom: 10px;
}

.selectedVariantFiDisclaimer {
  font-family: $font-default;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  color: #000000;
  margin-bottom: 10px;
}

.selectedVariantColorName {
  font-family: $font-default;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  color: #000;

  display: flex;
  align-items: center;
}

:global(.ecom-flow-revamp) .selectedVariantColorName {
  font-size: 18px;
  font-weight: 600;
}

.selectedVariantAdditonalPrice {
  margin-left: 8px;
  padding: 2px 4px;
  gap: 10px;
  border-radius: 4px;
  background-color: #000000;

  font-family: $font-default;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 14px */
  color: #ffffff;
}

.selectedVariantDescription {
  margin-top: 2px;

  font-family: $font-default;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  color: #737373;
}

.roundVariantSelector {
  composes: roundVariantSelector from '../../../../styles/variants.module.scss';
}

:global(.ecom-flow-revamp) .roundVariantSelector {
  margin-bottom: 20px;
}

.mobileVariantImage {
  display: block;
  width: 100%;
  aspect-ratio: 4/3;

  img {
    width: 100%;
    overflow: hidden;
    object-fit: contain;
    aspect-ratio: 4/3;
  }

  @media (min-width: $lg-min-width) {
    display: none;
  }
}

.button {
}

.yellowButton {
  order: 0;
}

.yellowScribbleButton {
  order: 1;
}

.grayButton {
  order: 2;
}

.grayScribbleButton {
  order: 3;
}

.blueButton {
  order: 4;
}

.blueScribbleButton {
  order: 5;
}

.pinkButton {
  order: 6;
}

.pinkScribbleButton {
  order: 7;
}

.stravaButton {
  order: 8;
}

:global(.ecom-flow-revamp) .grayButton {
  order: 0;
}

:global(.ecom-flow-revamp) .blueButton {
  order: 1;
}

:global(.ecom-flow-revamp) .yellowButton {
  order: 2;
}

:global(.ecom-flow-revamp) .pinkButton {
  order: 3;
}

:global(.ecom-flow-revamp) .stravaButton {
  order: 4;
}

@media (max-width: $sm-max-width), (min-width: $lg-min-width) and (max-width: $lg-max-width) {
  .roundVariantSelector {
    max-width: 326px;
  }

  .yellowButton {
    order: 0;
  }

  .grayButton {
    order: 1;
  }

  .blueButton {
    order: 2;
  }

  .pinkButton {
    order: 3;
  }

  .yellowScribbleButton {
    order: 4;
  }

  .grayScribbleButton {
    order: 5;
  }

  .blueScribbleButton {
    order: 6;
  }

  .pinkScribbleButton {
    order: 7;
  }
}
