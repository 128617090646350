@import '../../../styles/variables';

.paymentContainer {
  h1 {
    font-family: $font-default;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: -0.02em;
    color: #000000;
    margin: 0 24px 32px 24px;
  }
}

.billingInfoContainer {
  padding: 0 20px 20px 20px;
}

.success {
  color: white;
  background: $green-200;
  padding: 1em;
  text-align: center;
  position: fixed;
  width: 80%;
}
