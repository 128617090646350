@import '../../../../../styles/variables';

.productAction {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  gap: 2em;
  margin-top: -1.5em;

  @media (min-width: $lg-min-width) {
    gap: 3em;
    margin-top: 0;
  }
}

.stickyCTA {
  position: sticky;
  position: -webkit-sticky;
  box-sizing: border-box;
  padding: 20px 16px 20px 16px;
  bottom: 0px;
  gap: 10px;
  opacity: 0;
  transition: opacity 0.5s ease;
  pointer-events: none;
  @media (max-width: 1025px) {
    position: fixed;
    padding: 14px 16px 14px 16px;
  }
}

.stickyCTA button {
  padding: 18px 0px 18px 0px;
  @media (max-width: 1025px) {
    height: 56px;
  }
}

.stickyCTA button:hover {
  background-color: #242424;
}

.visibleStickyCTA {
  opacity: 1;
  transition: opacity 0.5s ease;
  pointer-events: auto;
}

.addToCartCTA {
  background-color: white;
}

.stickyCTA:has(.descriptionText) {
  border-top: 0.33px solid rgba(0, 0, 0, 0.25);
  background: #ffffff;
  z-index: 1000;
  @media (max-width: 1025px) {
    box-shadow: 0px -2px 12px 0px rgba(0, 0, 0, 0.12);
  }
}

.descriptionText {
  display: flex;
  justify-content: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 140%;
}

.footerValueProps {
  font-size: 1rem;
  font-weight: 600;
  color: $type-light-secondary;
  text-align: center;
}
