@import '../../../styles/mixins';
@import '../../../styles/variables';

.main {
  @include webview-full-height();
  width: 100%;

  .contentWrapper {
    margin: 0 24px;
    .titleContainer {
      text-align: center;
    }

    h1 {
      font-size: 24px;
      font-weight: 600;
      line-height: 27px;
      margin-bottom: 32px;
      letter-spacing: -0.02em;
      font-family: $font-default;
      text-transform: unset;
    }

    .continueButton {
      margin-top: 48px;
      width: 100%;
    }
  }
}
