@import '../../../../styles/variables';

.container {
  font-family: $font-default;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
}

.section {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10em $mobile-padding;
  box-sizing: border-box;

  h1 {
    font-family: $font-title;
    text-transform: none;
    font-size: 3.5rem;
    margin-bottom: 0;
    text-align: center;
    line-height: 1em;
  }

  h2 {
    font-family: $font-title;
    font-size: 4rem;
    margin-bottom: 0.875em;
    text-align: center;
    line-height: 1em;
  }

  @media (max-width: $md-max-width) {
    padding: 4em $mobile-padding 0 $mobile-padding;
  }
}

.main {
  composes: section;

  p {
    font-size: 1rem;
    font-weight: 600;
    color: #737373;
    max-width: 448px;
    text-align: center;
  }
}

.referralsSection {
  composes: section;
  background-color: $bg-dark-primary;
  line-height: 1.4em;

  h2 {
    color: $type-dark-primary;
  }
}

.referralsSubtitle {
  font-size: 1.5rem;
  margin-bottom: 0.75em;
  font-weight: 700;
  color: $type-dark-primary;
  text-align: center;
  line-height: 1.4em;
}

.referralsDescription {
  color: $type-dark-secondary;
  font-weight: 500;
  margin-bottom: 3em;
  max-width: 390px;
  text-align: center;
}

.referralsContainer {
  gap: 2em;
  max-width: 612px;
  width: 100%;
  box-sizing: border-box;
  position: relative;
}

.saveAccount {
  composes: section;
  background-color: $bg-dark-primary;

  h2 {
    color: $type-dark-primary;
    margin-bottom: 0;
  }

  p {
    font-size: 1rem;
    font-weight: 600;
    color: #c2c2c2;
    text-align: center;
    max-width: 18em;

    a {
      color: $type-dark-primary;
      text-decoration: underline;
    }
  }
}

.passwordForm {
  composes: form from '../../../../styles/form.module.scss';

  width: 100%;
  max-width: 40em;
  box-sizing: border-box;
  padding: 0 $mobile-padding;
  margin-top: 4em;

  input[type='password'] {
    width: 100%;
    background-color: $bg-dark-primary;
    color: $type-dark-primary;
    border: 1px solid $type-dark-secondary;

    &.passwordError::placeholder {
      color: #ff4242;
    }
  }
}

.submitPasswordButton {
  background-color: #ffffff;
  color: #000000;
  width: 100%;
}

.surveyQuestionWrapper {
  margin-top: 40px;
  width: 613px;
  @media (max-width: $md-max-width) {
    width: 100%;
  }
}
