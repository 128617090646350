@import '../../styles/variables';

.actionContainer {
  margin: 1em 0 2em;
  display: flex;
  gap: 1em;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;

  & > * {
    display: flex;
    flex-grow: 1;
    flex-basis: 0;
  }

  @media (min-width: $md-min-width) {
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
}
